<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: "GridBlock",
  
})
</script>
<template>
  <div class="grid-block" :style="style">
    <slot></slot>
  </div>
</template>
<script name="GridBlock" lang="ts" setup>
import { computed } from "vue";

const props = defineProps({
  // 列数
  column: {
    type: [Number, String],
    // 2
    default: 2,
  },
  // 列间距
  columnGap: {
    type: [Number, String],
    // 0
    default: 0,
  },
  // 行间距
  rowGap: {
    type: [Number, String],
    // 28
    default: 28,
  },
});

const style = computed(() => {
  return {
    display: "grid",
    "grid-template-columns": `repeat(${props.column}, 1fr)`,
    "column-gap": `${props.columnGap}px`,
    "row-gap": `${props.rowGap}px`,
  };
});
</script>
