/* eslint-disable no-tabs */

// 自定义组件
import GridBlock from './GridBlock/index.vue'
import ProTable from './ProTable/index.vue'

const components: any = {
  GridBlock,
  ProTable,
}

components.install = function (Vue: any) {
  Object.keys(components).forEach((key) => {
    key !== 'install' && Vue.component(key, components[key])
  })
}

export default components
