export const STORAGE_KEY = 'admin-v1-token';

// actorguo@163.com
export const ADMIN_EMAIL = '047eabb9144985f6ba01cd20ac003e0f';

export const DATE_FORMAT = 'YYYY-MM-DD';

export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export const MONTH_FORMAT = 'YYYY-MM';
