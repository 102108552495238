import { STORAGE_KEY } from '@/constants/magicVariable';
import { RouterNameEnum } from '@/enums/RouterNameEnum';
import { message } from 'ant-design-vue';
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

const beforeEach = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  if (to.name !== RouterNameEnum.LOGIN && !localStorage.getItem(STORAGE_KEY)) {
    message.error('请先登录', 10);
    next({
      name: RouterNameEnum.LOGIN,
    });
  }
  next();
};

export default beforeEach;
