import { createRouter, createWebHistory } from 'vue-router'
import beforeEachHandler from './beforeEachHandler';
import { routesList as routes } from './routesList';

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(beforeEachHandler);

export default router;

