import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import pinia from './stores'
import components from './components'
import version from './plugins/versionChecker'
// @ts-ignore
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import '@/assets/font/iconfont.css'
import 'ant-design-vue/es/message/style/index.css'
import 'ant-design-vue/es/button/style/index.css'
import './style/tailwind.css'
import './style/index.less'

createApp(App)
    .use(ElementPlus, {
        locale: zhCn
    })
    .use(pinia)
    .use(router)
    .use(components)
    .use(version)
    .mount('#app')
