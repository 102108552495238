<template>
  <a-config-provider :locale="locale">
    <div v-if="searchColumns.length > 0" class="p-5 mb-5 bg-white">
      <a-form
        :model="searchForm"
        ref="searchFormRef"
        autocomplete="off"
        class="grid grid-cols-3 gap-6 2xl:grid-cols-4"
      >
        <a-form-item
          v-show="index <= 2 ? true : index >= 3 && state.expansion"
          v-for="(column, index) in searchColumns"
          :key="index"
          :name="column.searchKey"
          :label="column.title"
          :label-width="column.labelWidth || '90px'"
          v-bind="column"
        >
          <a-select
            class="w-full"
            v-if="column.valueType === 'select'"
            v-model:value="searchForm[column.searchKey]"
            showSearch
            optionFilterProp="label"
            :mode="column.mode ? column.mode : ''"
            :placeholder="column.placeholder"
            :options="options[column.options]"
            :allowClear="column.allowClear"
            @change="(value: any) => { if (typeof column.change === 'function') column.change(value) }"
          />
          <a-select
            class="w-full"
            v-else-if="column.valueType === 'searchSelect'"
            v-model:value="searchForm[column.searchKey]"
            showSearch
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            :placeholder="column.placeholder"
            :options="options[column.options]"
            :allowClear="column.allowClear"
            @search="(query: any) => column.fetch(query, searchForm)"
          />
          <a-range-picker
            @change="(value: Date[]) => formatDateChange(value, column.searchKey, column.valueType)"
            v-else-if="['dateRange', 'dateTimeRange'].includes(column.valueType)"
            v-model:value="searchForm[column.searchKey]"
            :show-time="column.valueType === 'dateTimeRange'"
          />
          <a-input
            v-else
            v-model:value="searchForm[column.searchKey]"
            :placeholder="column.placeholder"
            :allowClear="column.allowClear"
          />
        </a-form-item>
        <a-form-item class="col-end-4 text-right 2xl:col-end-5" v-if="searchColumns.length">
          <a-button @click="resetForm">重置</a-button>
          <a-button class="ml-3" type="primary" @click="submitForm" :loading="state.loading">查询</a-button>
          <a-button
            v-if="searchColumns.length >= 4 && expandable"
            type="text"
            @click="state.expansion = !state.expansion"
          >
            <span v-show="!state.expansion">
              展开
              <down-outlined />
            </span>
            <span v-show="state.expansion">
              收起
              <up-outlined />
            </span>
          </a-button>
        </a-form-item>
      </a-form>
    </div>

    <slot name="cusConent" />

    <div class="p-5 bg-white">
      <div class="flex items-center justify-between">
        <h1 v-if="headerTitle" class="mb-3 text-base font-medium leading-8">{{ headerTitle }}</h1>
        <slot name="toolbar" />
      </div>
      <div class="selected" v-if="state.selectedRowKeys.length">
        <div class="alert">
          <span style="margin-left: 8px">{{ `已选 ${state.selectedRowKeys.length} 项` }}</span>
          <span @click="handelCancelRowSelected">取消选择</span>
        </div>
      </div>
      <a-table
        v-bind="$attrs"
        :pagination="false"
        :rowKey="rowKey"
        @change="handleTableChange"
        :showHeader="showHeader"
        :columns="tableColumns"
        :dataSource="state.data"
        :loading="state.loading"
        :bordered="showTabBorder"
        :row-selection="showRowSelection ? { selectedRowKeys: state.selectedRowKeys, onSelectAll: onSelectAll, onSelect: onSelect } : undefined"
      >
        <template #bodyCell="{ text, record, index, column }">
          <template v-if="column.scopedSlots">
            <slot
              :name="column.scopedSlots"
              :row="record"
              :text="text"
              :record="record"
              :index="index"
              :column="column"
            />
          </template>
        </template>
      </a-table>
    </div>

    <div v-if="showPage && state.total" class="px-5 pb-5 bg-white">
      <a-pagination
        class="flex items-center justify-end"
        size="small"
        show-size-changer
        show-quick-jumper
        @showSizeChange="handleSizeChange"
        @change="handleCurrentSizeChange"
        :show-total="(total: any) => `总共 ${total} 条`"
        :current="pageNo"
        :pageSize="pageSize"
        :pageSizeOptions="['10', '20', '50', '100']"
        :total="state.total"
      />
    </div>
  </a-config-provider>
</template>
<script lang="ts">
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  shallowReactive,
} from "vue";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import { DownOutlined, UpOutlined } from "@ant-design/icons-vue";
import dayjs from "dayjs";

type ItemRow = {
  id: number;
  domainAccount: string;
};

type RowProps = {
  // 是否选中
  selected: Boolean;
  // 选中的row数组
  selectedRows: ItemRow[];
  // 取消选中需要过滤掉的idList
  filterIds: string[];
};

const filterEmpty = (obj: Record<string, any>) =>
  Object.keys(obj)
    .filter((k) => obj[k] !== "" && obj[k] !== null)
    .reduce((acc, key) => ({
      ...acc,
      [key]: obj[key]
    }), {});

export default defineComponent({
  name: "ProTable",
  components: { DownOutlined, UpOutlined },
  props: {
    // 搜索 表格列定义
    columns: {
      type: Array,
      required: true,
    },
    // 请求数据方法
    request: {
      type: Function,
      required: true,
    },
    // 是否需要 收起 展开 功能
    expandable: {
      type: Boolean,
      default: true,
    },
    // 下拉查询项集合
    options: {
      type: Object,
      default: () => {},
    },
    // 是否显示 表格表头 默认显示
    showHeader: {
      type: Boolean,
      default: true,
    },
    // 是否显示表格边框 默认不显示
    showTabBorder: {
      type: Boolean,
      default: false,
    },
    // 是否显示分页 默认显示
    showPage: {
      type: Boolean,
      default: true,
    },
    // 表格行 key 值，默认是 id
    rowKey: {
      type: String,
      default: "id",
    },
    // 表格标题， 有值则显示 默认是 没有
    headerTitle: {
      type: String,
      default: "",
    },
    // 筛选默认展示对象
    defaultValue: {
      type: Object,
      required: false,
    },
    showRowSelection: {
      type: Boolean,
    },
    pageNo: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    isAutoLoadData: {
      type: Boolean,
      default: true,
    }
  },
  emits: ['update:pageNo', 'update:pageSize'],
  // eslint-disable-next-line vue/no-setup-props-destructure
  setup({ columns, request, defaultValue, rowKey, pageNo, pageSize, isAutoLoadData }, { emit }) {
    // 用来获取表单实例
    const searchFormRef = ref(null);

    // 设置语言为zhCN
    const locale = ref(zhCN);

    // 该组件的内部状态
    let state = reactive({
      data: [],
      total: 0,
      sorterType: undefined,
      sorterField: undefined,
      loading: false,
      expansion: true,
      // 控制当前pageSize是否改变
      changePageSize: false,
      selectedRowKeys: [],
      selectedRows: [],
    });
    
    // 根据 columns 的配置项，筛选出 搜索表单
    const searchColumns = columns
      .filter((i: any) => !i.hideInSearch)
      .map((i: any) => ({
        ...i,
        searchKey: i.searchKey || i.dataIndex,
        placeholder:
          i.placeholder || i.valueType === "select"
            ? `请选择${i.title}`
            : `请输入${i.title}`,
        allowClear: i.allowClear !== undefined ? i.allowClear : true,
      }));

    // 根据 columns 的配置项，筛选出 表格展示列
    const tableColumns = columns.filter((i: any) => !i.hideInTable);

    // 表单的初始值，也可以从 columns 取得初始值。这里统一设置成了 undefined
    const searchForm = shallowReactive(
      searchColumns.reduce((prev, curr) => {
        if (defaultValue && curr.searchKey in defaultValue) {
          prev[curr.searchKey] = defaultValue[curr.searchKey];
        } else {
          prev[curr.searchKey] = undefined;
        }
        return prev;
      }, {})
    );

    /*
     * 加载表格数据
     */
    const loadData = async () => {
      try {
        state.loading = true;
        // 根据业务调用项目的request方法
        const { data, total } = await request({
          pageNo,
          pageSize,
          sorterType: state.sorterType,
          sorterField: state.sorterField,
          ...filterEmpty(searchForm),
        });

        state.data = data;
        state.total = total;
        state.loading = false;
      } catch (error) {
        state.loading = false;
        console.error(error)
      }
    };

    /*
     * 查询
     */
    const submitForm = async () => {
      // if (!searchFormRef.value) return;
      try {
        await loadData();
      } catch (error) {
        console.error(error)
      }
    };

    /*
     * 重置
     */
    const resetForm = async () => {
      try {
        for (const key in searchForm) {
          if (defaultValue && key in defaultValue) {
            searchForm[key] = defaultValue[key];
          } else {
            searchForm[key] = undefined;
          }
        }
        emit('update:pageNo', 1);
        emit('update:pageSize', 10);
        state.selectedRowKeys = [];
        state.selectedRows = [];
        await loadData();
      } catch (error) {
        console.error(error)
      }
    };

    // pageSize 变化的回调
    const handleSizeChange = (val: number) => {
      state.changePageSize = true;
      emit('update:pageSize', val);
    };

    // 页码改变的回调
    const handleCurrentSizeChange = async (page: number, pageSize: number) => {
      // pageSize若改变需重置到pageNo页码为1
      emit('update:pageNo', state.changePageSize ? 1 : page);
      emit('update:pageSize', pageSize);
      state.changePageSize = false;
      await loadData();
    };

    // 对日期时间格式 转化
    const formatDateChange = (value: Date[], key: string, dateType: string) => {
      searchForm[key] = value.map((v: Date) => {
          return dayjs(v).format(dateType === "dateTimeRange" ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD")
      });
    };

    /*
     * 处理 table 变化，对相应值做处理
     */
    const handleTableChange = async (
      pagination: any,
      filters: any,
      sorter: { order: undefined; field: undefined }
    ) => {
      state.sorterType = sorter.order;
      state.sorterField = sorter.field;
      emit('update:pageNo', pageNo);
      await loadData();
    };

    /*
     * 重新加载
     */
    const reloadTable = async () => {
      try {
        state.selectedRowKeys = [];
        state.selectedRows = [];
        await loadData();
      } catch (error) {
        console.error(error)
      }
    };

    onMounted(async () => {
      if (isAutoLoadData) {
        await loadData();
      }
    });

    // 处理rowChange
    const handleRowChange = (props: RowProps) => {
      const { selected, selectedRows, filterIds } = props;
      let rowList: ItemRow[] = state?.selectedRows ?? [];
      const keys: number[] = [];
      if (selected) {
        // 选中状态
        selectedRows.forEach((item) => {
          if (item && item?.[rowKey as keyof ItemRow]) {
            rowList.push(item);
          }
        });
      } else {
        // 取消勾选 需过滤出取消的row
        const tempRow: any[] = [];
        rowList.forEach((item) => {
          if (!filterIds.includes(item[rowKey as keyof ItemRow] as string)) {
            tempRow.push(item);
          }
        });
        rowList = tempRow;
      }
      rowList.forEach((item) => keys.push(item[rowKey as keyof ItemRow] as number));
      state.selectedRows = rowList as any;
      state.selectedRowKeys = keys as any;
    };
    // 单条选中 为了统一处理数据全部使用数组形式处理
    interface SelectRecordInterface {
      id: number;
      domainAccount: string;
    }
    const onSelect = (
      record: SelectRecordInterface,
      selected: boolean
    ) => {
      handleRowChange({
        selected,
        selectedRows: [record],
        filterIds: [record[rowKey as keyof SelectRecordInterface] as string],
      });
    };

    // 响应全选
    const onSelectAll = (
      selected: boolean,
      selectedRows: [],
      changeRows: ItemRow[]
    ) => {
      const filterIds: number | string[] = [];
      changeRows.forEach((item) => filterIds.push(item[rowKey as keyof ItemRow] as string));
      handleRowChange({ selected, selectedRows, filterIds });
    };

    const handelCancelRowSelected = () => {
      state.selectedRowKeys = [];
      state.selectedRows = [];
    };

    return {
      locale,
      formatDateChange,
      reloadTable,
      handleTableChange,
      searchColumns,
      tableColumns,
      searchForm,
      resetForm,
      handleSizeChange,
      handleCurrentSizeChange,
      searchFormRef,
      submitForm,
      state,
      onSelectAll,
      onSelect,
      handelCancelRowSelected,
    };
  },
});
</script>
<style lang="less" scoped>
.ant-form-item {
  margin-bottom: 0;
}

.selected {
  background-color: #e6f7ff;
  border: 1px solid #7acaff;
  padding: 12px 10px;
  margin-bottom: 16px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  span:nth-child(2) {
    color: #1890ff;
    cursor: pointer;
  }
  .alert {
    box-sizing: border-box;
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum", "tnum";
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 15px;
    word-wrap: break-word;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
</style>
