import {RouterNameEnum} from '@/enums/RouterNameEnum'

export const routesList = [
    {
        path: '/login',
        name: RouterNameEnum.LOGIN,
        component: () => import('@/views/login/index.vue'),
    },
    {
        path: '/',
        name: RouterNameEnum.DEFAULT_LAYOUT,
        redirect: '/home',
        component: () => import('@/layout/index.vue'),
        children: [
            {
                path: '/home',
                name: RouterNameEnum.HOME,
                meta: {
                    title: '首页',
                    icon: 'icon-home',
                    desc: '首页',
                },
                component: () => import('@/views/home/index.vue'),
            },
            {
                path: '/transaction',
                redirect: '/transaction/list',
                name: RouterNameEnum.TRANSACTION,
                meta: {
                    title: '交易管理',
                    icon: 'icon-icon_user',
                    desc: '显示所有流入及流出账户的资金数据。',
                },
                children: [
                    {
                        path: '/bill',
                        name: RouterNameEnum.BILL,
                        component: () => import('@/views/bill/index.vue'),
                        meta: {
                            title: '订单列表',
                            desc: '在此查看商户所有订单'
                        }
                    },
                    {
                        path: '/bill/:id',
                        name: RouterNameEnum.BILL_DETAIL,
                        component: () => import('@/views/bill/_id.vue'),
                        meta: {
                            title: '订单详情',
                            desc: '在此查看订单详细信息',
                            isNotShow: true,
                            showBack: true
                        }
                    },
                    {
                        path: '/transaction/list',
                        name: RouterNameEnum.TRANSACTION_LIST,
                        component: () => import('@/views/transaction/list.vue'),
                        meta: {
                            title: '交易列表',
                            desc: '显示所有流入及流出账户的资金数据。',
                        },
                    },
                    {
                        path: '/transaction/detail',
                        name: RouterNameEnum.TRANSACTION_DETAIL,
                        component: () => import('@/views/transaction/detail.vue'),
                        meta: {
                            title: '交易明细',
                            desc: '',
                            isNotShow: true,
                            showBack: true,
                        },
                    },
                    {
                        path: '/chargeback',
                        name: RouterNameEnum.CHARGEBACK,
                        component: () => import('@/views/chargeback/index.vue'),
                        meta: {
                            title: '拒付',
                            desc: '显示所有用户向信用卡发起的退款订单',
                        },
                    },
                    {
                        path: '/replacePayment',
                        name: RouterNameEnum.REPLACE_PAYMENT,
                        component: () => import('@/views/replacePayment/index.vue'),
                        meta: {
                            title: '代付',
                            desc: '商户的付款记录',
                        },
                    }
                ]
            },
            {
                path: '/activity',
                name: RouterNameEnum.ACTIVITY,
                meta: {
                    title: '营销活动',
                    icon: 'icon-yingxiaohuodong',
                    desc: '在此查看各个活动',
                },
                children: [
                    {
                        path: 'xy',
                        name: RouterNameEnum.ACTIVITY_XY,
                        component: () => import('@/views/activity/xy/index.vue'),
                        meta: {
                            title: '买X送Y'
                        },
                    },
                    {
                        path: 'addUp',
                        name: RouterNameEnum.ACTIVITY_ADD_UP,
                        component: () => import('@/views/activity/addUp/index.vue'),
                        meta: {
                            title: '累充'
                        },
                    },
                    {
                        path: 'addUp/replay',
                        name: RouterNameEnum.ACTIVITY_ADD_UP_REPLAY,
                        component: () => import('@/views/activity/addUp/replay.vue'),
                        meta: {
                            title: '累充复盘',
                            desc: '复盘活动数据',
                            isNotShow: true,
                            showBack: true
                        },
                    },
                    {
                        path: 'firstBuy',
                        name: RouterNameEnum.ACTIVITY_FIRST_BUY,
                        component: () => import('@/views/activity/firstBuy/index.vue'),
                        meta: {
                            title: '商品首充'
                        }
                    },
                    {
                        path: 'limit',
                        name: RouterNameEnum.ACTIVITY_LIMIT,
                        component: () => import('@/views/activity/limit/index.vue'),
                        meta: {
                            title: '商品限购'
                        }
                    },
                    {
                        path: 'recharge',
                        name: RouterNameEnum.ACTIVITY_RECHARGE,
                        component: () => import('@/views/activity/recharge/index.vue'),
                        meta: {
                            title: '充值折扣'
                        }
                    },
                    {
                        path: 'sign',
                        name: RouterNameEnum.ACTIVITY_SIGN,
                        component: () => import('@/views/activity/sign/index.vue'),
                        meta: {
                            title: '每日签到'
                        }
                    },
                    {
                        path: 'sign/replay',
                        name: RouterNameEnum.ACTIVITY_SIGN_REPLAY,
                        component: () => import('@/views/activity/sign/replay.vue'),
                        meta: {
                            title: '每日签到复盘',
                            desc: '在此查看该活动复盘',
                            showBack: true,
                            isNotShow: true
                        }
                    },
                    {
                        path: 'consecutive',
                        name: RouterNameEnum.ACTIVITY_CONSECUTIVE,
                        component: () => import('@/views/activity/consecutive/index.vue'),
                        meta: {
                            title: '连续充值'
                        }
                    },
                    {
                        path: 'consecutive/replay',
                        name: RouterNameEnum.ACTIVITY_CONSECUTIVE_REPLAY,
                        component: () => import('@/views/activity/consecutive/replay.vue'),
                        meta: {
                            title: '连续充值复盘',
                            isNotShow: true
                        }
                    },
                    {
                        path: 'lottery',
                        name: RouterNameEnum.ACTIVITY_LOTTERY,
                        component: () => import('@/views/activity/lottery/index.vue'),
                        meta: {
                            title: '抽奖'
                        }
                    },
                    {
                        path: 'lottery/replay',
                        name: RouterNameEnum.ACTIVITY_LOTTERY_REPLAY,
                        component: () => import('@/views/activity/lottery/replay.vue'),
                        meta: {
                            title: '抽奖复盘',
                            isNotShow: true
                        }
                    },
                    {
                        path: 'convertKey',
                        name: RouterNameEnum.ACTIVITY_CONVERT_KEY,
                        component: () => import('@/views/activity/convertKey/index.vue'),
                        meta: {
                            title: '兑换码',
                            desc: '在此查看各个活动'
                        }
                    }
                ]
            },
            // 商户管理
            {
                path: '/merchant',
                name: RouterNameEnum.MERCHANT,
                redirect: '/merchantList',
                meta: {
                    title: '商户管理',
                    desc: '',
                    icon: 'icon-shanghuguanli',
                },
                children: [
                    {
                        path: '/merchantList',
                        name: RouterNameEnum.MERCHANT_LIST,
                        component: () => import('@/views/merchant/index.vue'),
                        meta: {
                            title: '商户列表',
                            desc: ''
                        },
                    },
                    {
                        path: '/merchant/:merchantId',
                        name: RouterNameEnum.MERCHANT_APP,
                        component: () => import('@/views/merchant/_merchantId/index.vue'),
                        meta: {
                            title: '商户详情',
                            isNotShow: true,
                            showBack: true
                        }
                    },
                    {
                        path: '/merchant/:merchantId/project/:projectId',
                        name: RouterNameEnum.MERCHANT_APP_STORE,
                        component: () => import('@/views/merchant/_merchantId/project/_projectId/index.vue'),
                        meta: {
                            title: '应用商城',
                            isNotShow: true
                        }
                    },
                    {
                        path: '/merchant/:merchantId/project/:projectId/webhook',
                        name: RouterNameEnum.MERCHANT_APP_WEBHOOK,
                        component: () => import('@/views/merchant/_merchantId/project/_projectId/webhook/index.vue'),
                        meta: {
                            title: 'webhook',
                            isNotShow: true,
                            showBack: true
                        }
                    },
                    {
                        path: '/merchant/:merchantId/project/:projectId/store/:storeId/domain',
                        name: RouterNameEnum.MERCHANT_APP_STORE_DOMAIN,
                        component: () => import('@/views/merchant/_merchantId/project/_projectId/_storeId/domain/index.vue'),
                        meta: {
                            title: '商城域名',
                            isNotShow: true
                        }
                    },
                    {
                        path: '/payment',
                        name: RouterNameEnum.PAYMENT,
                        component: () => import('@/views/payment/index.vue'),
                        meta: {
                            title: '支付方式管理',
                            desc: '',
                            icon: 'icon-zhifufangshi',
                        },
                    },
                    {
                        path: '/quote',
                        name: RouterNameEnum.QUOTE,
                        component: () => import('@/views/quote/index.vue'),
                        meta: {
                            title: '报价管理',
                            desc: ''
                        },
                    },
                    {
                        path: '/currency',
                        name: RouterNameEnum.CURRENCY,
                        component: () => import('@/views/currency/index.vue'),
                        meta: {
                            title: '定价货币',
                            desc: '',
                            icon: 'icon-huobiduihuan',
                        },
                    },
                    {
                        path: '/settleManage',
                        name: RouterNameEnum.SETTLE_MANAGE,
                        component: () => import('@/views/settle/manage.vue'),
                        meta: {
                            title: '结算管理',
                            desc: '',
                            icon: 'icon-xianjinqingfenjiesuan',
                        }
                    }
                ]
            },
            {
                path: '/finance',
                name: RouterNameEnum.FINANCE,
                meta: {
                    title: '财务管理',
                    icon: 'icon-zhifufangshi'
                },
                children: [
                    {
                        path: '/settle',
                        name: RouterNameEnum.SETTLE,
                        component: () => import('@/views/settle/index.vue'),
                        meta: {
                            title: '支付厂商回款',
                            desc: ''
                        },
                    },
                    {
                        path: '/merchant-settlement',
                        name: RouterNameEnum.MERCHANT_SETTLEMENT,
                        component: () => import('@/views/merchantSettlement/index.vue'),
                        meta: {
                            title: '商户结算',
                            desc: '周期结算列表',
                        },
                    },
                    {
                        path: '/rechargeHistory',
                        name: RouterNameEnum.RECHARGE_HISTORY,
                        component: () => import('@/views/recharge/history.vue'),
                        meta: {
                            title: '充值记录',
                            desc: '',
                            showBack: false
                        }
                    },
                    {
                        path: '/withdrawCash',
                        name: RouterNameEnum.WITHDRAW_CASH,
                        component: () => import('@/views/withdrawCash/index.vue'),
                        meta: {
                            title: '提现',
                            desc: '存储商户的提现记录',
                            showBack: false
                        }
                    }
                ]
            },
            {
                path: '/vip',
                name: RouterNameEnum.VIP,
                meta: {
                    title: '会员中心',
                    icon: 'icon-vip-management'
                },
                children: [
                    {
                        path: 'rank',
                        component: () => import('@/views/vip/rank/index.vue'),
                        name: RouterNameEnum.VIP_RANK,
                        meta: {
                            title: '会员等级管理',
                            desc: ''
                        }
                    },
                    {
                        path: 'benefit',
                        component: () => import('@/views/vip/benefit/index.vue'),
                        name: RouterNameEnum.VIP_BENEFIT,
                        meta: {
                            isNotShow: true,
                            title: '权益配置',
                            desc: ''
                        }
                    },
                    {
                        path: 'points',
                        component: () => import('@/views/vip/points/index.vue'),
                        name: RouterNameEnum.VIP_POINTS,
                        meta: {
                            title: '积分商城',
                            desc: ''
                        }
                    },
                    {
                        path: 'config',
                        component: () => import('@/views/vip/config/index.vue'),
                        name: RouterNameEnum.VIP_CONFIG,
                        meta: {
                            title: '其他配置',
                            desc: ''
                        }
                    }
                ]
            },
            {
                path: '/analysis',
                name: RouterNameEnum.ANALYSIS,
                component: () => import('@/views/analysis/index.vue'),
                meta: {
                    title: '数据分析',
                    desc: '基于商户的交易数据展示多维度的分析',
                    icon: 'icon-fenxi'
                }
            },
            {
                path: '/language',
                name: RouterNameEnum.Language,
                component: () => import('@/views/language/index.vue'),
                meta: {
                    title: '语言库',
                    desc: '管理平台中的语言内容',
                    icon: 'icon-guojihua'
                }
            },
            {
                path: '/dev/userUpdate',
                name: RouterNameEnum.USER_UPDATE,
                component: () => import('@/views/dev/userUpdate/index.vue'),
                meta: {
                    title: '用户属性调试',
                    icon: 'icon-debug'
                }
            },
            {
                path: '/demo',
                name: RouterNameEnum.DEMO,
                component: () => import('@/views/demo/index.vue'),
                meta: {
                    title: '开发页',
                    icon: 'icon-debug',
                    isNotShow: true
                }
            }
        ]
    },
    {
        path: '/test',
        name: RouterNameEnum.TEST,
        component: () => import('@/views/test/index.vue'),
        meta: {
            title: '开发页面',
            isNotShow: true
        }
    },
    {
        path: '/404',
        name: RouterNameEnum.NOT_FOUND,
        component: () => import('@/views/notFound/index.vue'),
    },
    {
        path: '/:pathMatch(.*)*',
        name: RouterNameEnum.ERROR,
        redirect: '/404',
    }
]
